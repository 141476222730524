<template>
  <div class="simditor">
    <textarea :id="id"></textarea>
  </div>
</template>
<script>
import $ from "jquery";
import Simditor from "simditor";
import "simditor/styles/simditor.css";
export default {
  name: "Simditor",
  data() {
    return {
      editor: null,
      value:''
    };
  },
  model:{
    prop:'data',
    event:'change'
  },
  props: {
    id: {
      type:String
    }, //这里传入动态id，一个页面能使用多个编辑器
    options: {
      //配置参数
      type: Object,
      default() {
        return {
          placeholder:'请输入内容',
          toolbar:[
            'title',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'fontScale',
            'color',
            'ol',
            'ul',
            'blockquote',
            'code',
            'table',
            'link',
            'image',
            'hr',
            'indent',
            'outdent',
            'alignment'
          ]
        };
      },
    },
    data:{
      type:String
    }
  },
  watch:{
    value:{
      handler(val){
        this.valueChange(val)
      }
    },
    data:{
      handler(val) {
        this.$nextTick(()=>{
          console.log(val)
          this.value = val
          if(this.editor) {
            const value = this.editor.getValue();
            if(value !== val) {
              this.editor.setValue(val || '')
            }
          }
        })
      },
      immediate:true
    }
  },
  mounted() {
    let vm = this;
    let opt = {
      textarea: $(`#${vm.id}`),
      ...this.options
    }
    this.editor = new Simditor(opt);
    this.editor.on("valuechanged", (e, src) => {
      this.value = this.editor.getValue();
    });
  },
  methods: {
    valueChange(val) {
      this.$emit("change", val);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
