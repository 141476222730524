<template>
  <div class="projectInfo" :style="{marginLeft:leftWidth + 'px'}">
    <div class="projectIcon">
      <img width="40px" style="background-color: black" :src="data.projectLogo">
    </div>
    <div class="mainInfo">
      <h3 class="projectTitle">{{ data.projectName }}</h3>
      <p class="location">{{ data.location }}</p>
      <p class="date">{{ data.beginDate }}</p>
    </div>
    <transition name="animate__animated animate__fade"
                leave-active-class="animate__fadeOutLeft"
                enter-active-class="animate__fadeInLeft">
      <div v-show="showMore" class="additionalInfo">
        <div class="client" v-if="data.client">
          <p class="key">{{$t('client')}}</p>
          <p class="value">{{ data.client }}</p>
        </div>
        <div class="size" v-if="data.size">
          <p class="key">{{$t('area')}}</p>
          <p class="value">{{ data.size }}</p>
        </div>
        <div class="status" v-if="data.status">
          <p class="key">{{$t('status')}}</p>
          <p class="value">{{ data.status }}</p>
        </div>
      </div>
    </transition>
    <transition name="animate__animated animate__fade"
                leave-active-class="animate__fadeOutDown"
                enter-active-class="animate__fadeInUp">
      <div v-show="showMore" class="additionalInfo" style="    margin-top: 100%;">
        <div v-html="data.content"></div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ProjectInfo",
  props:['data','showMore'],
  computed:{
    leftWidth(){
      return 0
      // return document.body.clientWidth /2 - 500
    }
  }
}
</script>

<style scoped>
.projectIcon {
  margin-bottom: 30px;
}
.projectInfo {
  /*height: 600px;*/
  width: 150px;
  text-align: right;
}
.mainInfo {
  font-size: 10px;
  color: #1b1b1b;
  margin-bottom: 30px;
  line-height: 1.5em;
}
.projectTitle {
  font-size: 12px;
  color: #1b1b1b;
}
.additionalInfo div {
  margin-bottom: 15px;
  line-height: 0.5em;
}
.key {
  font-size: 11px;
  color: #797979;
}

.value {
  font-size: 10px;
  color: #1b1b1b;
}
</style>
