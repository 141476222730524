<template>
  <div id="app">
    <full-loading  v-show="loading" key="loading" id="FullLoading"></full-loading>

    <Menus v-if="!isMobile"></Menus>
    <keep-alive>
      <router-view>
      </router-view>
    </keep-alive>
  </div>

</template>

<script>
import FullLoading from "./components/FullLoading.vue";
import Menus from "./components/Menus.vue";
import {isMobile} from "@/utils/common";
import {getProjects} from "@/api/api";
import anime from "animejs";

export default {
  name: "App",
  components: {
    Menus,
    FullLoading,
  },
  data() {
    return {
      imgs: [],
      count: 0,
      loading: true,
      initData:null,
    };
  },
  watch: {
    // count(val) {
    //   if (this.imgs.length === val) {
    //     this.closeLoading();
    //   }
    // },
  },
  computed:{
    isMobile(){
      return isMobile();
    }
  },
  created() {
    // getProjects().then(res => {
    //   if(res.data.code === 200) {
    //     this.initData = res.data.data;
    //     res.data.data.forEach(item => {
    //       this.imgs.push(...(item.projectLogo + "," + item.cover).split(","))
    //     })
    //     console.log(this.imgs.length)
    //     this.renderImg();
    //   }
    // })

    setTimeout(()=>{
      this.closeLoading()
    },5000)
  },
  methods: {
      renderImg(){
        const renderOver = function () {
          this.count++;
          console.log('渲染',this.count)
        }.bind(this)
        this.imgs.forEach(item => {
          const img = new Image()
          img.src = item;
          if(img.complete) {
            console.log('有缓存')
            renderOver();
            return;
          }
          img.onload = renderOver
          img.onerror = renderOver
        })
      },
      closeLoading(){
        this.$event.emit('ImgLoadComplete',this.initData);
        let ani = anime({
          targets: ["#FullLoading"],
          translateY:"-100vh",
          easing: "cubicBezier(0.545, 0.115, 0.285, 0.345)",
        })
        ani.finished.then(() => {
          this.$nextTick(()=>{
            this.loading = false;
          })
        });
      }
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  font-size: 14px;
  position: absolute;
}
.main_content {
  padding-left: 150px;
  padding-right: 50px;
  height: calc(100% - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.mobile_header {
  padding: 20px 0;
}

.anime-enter-active,
.anime-leave-active {
  transition: all 1s;
}
.anime-leave-from { transform: translateY(0); }
.anime-leave-to { transform: translateY(-100%); }

.anime-enter-from { transform: translateY(100%); }
.anime-enter-to { transform: translate(0); }

.screen-container{
  position: relative;
  width: 100%;
  height: 100%;
}
.slide-enter-active,
.slide-leave-active{
  transition: all 1s ease;
  position: absolute;
}
.slide-enter{
  transform: translateX(100%);
  opacity: 0;
}
.slide-leave-to{
  transform: translateX(-100%);
  opacity: 0;
}

</style>
