<template>
  <div style="padding: 20px">
    <el-button style="margin-bottom: 20px" @click="create" type="primary" size="small">新增</el-button>
    <el-table
        :data="tableData"
        border
        style="width: 100%">
      <el-table-column
          prop="projectName"
          label="项目名称"
          width="180">
      </el-table-column>
      <el-table-column
          prop="location"
          label="位置"
          width="180">
      </el-table-column>
      <el-table-column
          prop="beginDate"
          label="开始年份"
          width="180">
      </el-table-column>
      <el-table-column
          prop="size"
          label="面积"
          width="180">
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          width="180">
      </el-table-column>
      <el-table-column
          prop="client"
          label="客户"
          width="180">
      </el-table-column>
      <el-table-column
          prop="type"
          label="类型"
          width="180">
          <template slot-scope="scope">
            {{  scope.row.type }}
          </template>
      </el-table-column>
      <el-table-column
          prop="projectLogo"
          label="图标"
          width="180">
        <template slot-scope="scope">
          <el-image  style="width: 100px; height: 100px" :src="scope.row.projectLogo"></el-image>
        </template>
      </el-table-column>
      <el-table-column
          prop="opts"
          label="效果图"
          width="180">
        <template slot-scope="scope" v-if="scope.row.opts">
          <el-image v-for="(item,index) in scope.row.opts.split(',')" :key="index" style="width: 100px; height: 100px" :src="item"></el-image>
        </template>
      </el-table-column>
      <el-table-column
          prop="content"
          label="文字内容">
        <template slot-scope="scope">
          <div v-html="scope.row.content"></div>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
          <el-button @click="del(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <project-form ref="form" @save="save" ></project-form>
  </div>
</template>

<script>
import {deleteProjects, download, getProjects, insertProjects, updateProjects, upload} from "@/api/api";
import ProjectForm from "@/components/admin/project/ProjectForm";
export default {
  name: "ProjectTable",
  components: {ProjectForm},
  props:['lang'],
  watch:{
    lang(){
      this.init();
    }
  },
  data(){
    return {
      tableData:[],
      type:'project'
    }
  },
  mounted() {
    this.init()
  },
  computed:{
    fileName(){
      return `${this.type}_${this.lang}.json`
    }
  },
  methods:{
    init(){
      getProjects().then(res => {
        if(res.data.code !== 500 ) {
          this.tableData = res.data.data;
        }
      })
    },
    save(newData){
      this.loading = true;
      let action = newData.id ? updateProjects(newData) : insertProjects(newData)
      action.then(res => {
        if(res.data.code === 200 ) {
          this.$message.success(res.data.msg)
          this.init();
          this.loading = false;
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    create(){
      this.$refs.form.open({})
    },
    edit(row){
      this.$refs.form.open(row)
    },
    del(row){
      deleteProjects(row).then(res => {
        if(res.data.code === 200 ) {
          this.$message.success(res.data.msg)
          this.init();
          this.loading = false;
        } else {
          this.$message.error(res.data.msg)
        }
      })

    },
  }
}
</script>

<style scoped>

</style>
