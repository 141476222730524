<template>
  <el-dialog
    :visible="dialogVisible"
    width="60%"
  >
    <div slot="title" class="bed-header ">
      <span>图库</span>
      <div style="margin-right: 80px;">
        <el-upload class="upload-demo" action="https://www.bv-design.cn/api/v2/upload" list-type="picture" :show-file-list="false"
                   :on-success="uploadSuccess">
              <el-button  size="mini">上传文件</el-button>
        </el-upload>
        <el-button  size="mini" type="danger">删除</el-button>
      </div>
    </div>
    <div class="bed-panel">
      <el-tree class="bed-menu" :data="menus" :props="{
          children: 'children',
          label: 'name',
          disabled: 'disabled'
        }">
        <span class="custom-tree-node" slot-scope="{ node}">
          <span class="custom-tree-node-item">{{ node.label }}</span>
        </span>
      </el-tree>
      <div class="bed-images">
        <check-box-group :data="list">
          <template v-slot:default="scope">
            <div style="width: 100px">
              <el-image :src="scope.item.url" fit="contain" style="height: 100px"></el-image>
              <div class="img-name" :title="scope.item.url">{{scope.item.url.split('/').pop()}}</div>
            </div>
          </template>
        </check-box-group>
      </div>
    </div>
    <div slot="footer" class="bed-footer">
      <div>
        <el-button type="primary" size="mini">确定</el-button>
        <el-button type="" size="mini">取消</el-button>
      </div>
      <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="pageSize"
          :current-page="pageIndex"
          @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import CheckBoxGroup from "@/components/CheckBoxGroup";
import {getImages, getImageType,getImgUrl} from "@/api/api";
import {recursiveToTree} from "@/utils/common";
import { insertImage } from '../../api/api';
export default {
  name: "ImgBed",
  components: {CheckBoxGroup},
  data(){
    return {
      dialogVisible:false,
      list:[],
      menus: [],
      pageSize:20,
      pageIndex:1,
      total:0
    }
  },
  mounted() {
   this.refreshData();
    getImageType().then(res => {
      const list = res.data.data
      this.menus = recursiveToTree(list)
      console.log(this.menus)
    })
  },
  methods:{
    open(){
      this.dialogVisible = true;
    },
    pageChange(e) {
      this.pageIndex = e;
      this.refreshData();
    },
    refreshData(){
      getImages({pageSize:this.pageSize,pageIndex:this.pageIndex - 1}).then(res => {
        this.list = res.data.data
        this.total = res.data.cnt
      })
    },
    uploadSuccess(res,file){
      const imgSrc = getImgUrl(file)
      insertImage({
        url:imgSrc,
        typeId:2
      }).then(res => {
        this.pageIndex = 1;
        this.refreshData()  
      })
    }
  }
}
</script>

<style scoped>
.bed-panel {
  display: flex;
  width: 100%;
}
.bed-menu {
  width: 15%;
}
.bed-header {
  width: 100%;
  box-sizing: content-box;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bed-footer {
  height: 80px;
  width: 85%;
  padding-left: 15%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bed-images {
  width: 85%;
  overflow-y: scroll;
  height: 500px;
  border-left: 1px solid #c4c4c4;
  padding: 10px;

}
.img-name {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-tree-node-item {

}
</style>
