export function scrollIntoViewCenter (el,scrollEl){
  const opt = {
    top: getScrollTopByCenter(el,scrollEl) ,
    behavior:'smooth'
  }
  scrollEl.scrollTo(opt);
}
export function getScrollTopByCenter(el,scrollEl) {
  if(!el || !el.getBoundingClientRect) {
    return 0 ;
  }
  const {top , height} = el.getBoundingClientRect();
  const clientHeight = document.body.clientHeight ;
  return scrollEl.scrollTop - (clientHeight / 2 - (top + height / 2)) ;
}

export function scrollTop(el,scrollEl) {
  if(!el || !el.getBoundingClientRect) {
    return 0 ;
  }
  const {top , height} = el.getBoundingClientRect();
  const clientHeight = document.body.clientHeight ;
  const opt = {
    top: scrollEl.scrollTop + top -  200  ,
    behavior:'smooth'
  }
  scrollEl.scrollTo(opt);
}