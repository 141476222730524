<template>
  <div class='News'>
    <mobile-header>
      <h1 class="mobile_header">NEWS</h1>
      <div v-for="(item,index) in data" :key="index">
        <div style="font-size: 14px;color:#d5d5d5;line-height: 2em;">{{ item.date }}</div>
        <el-image :src="item.cover"></el-image>
        <div style="font-weight: bold;font-size: 18px;">{{ item.title }}</div>
        <div style="font-size: 16px;color:#bdbdbd;" v-html="item.content"></div>
      </div>
    </mobile-header>
  </div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader";
import {getNews} from "@/api/api";
export default {
  name: "News",
  components: {MobileHeader},
  data(){
    return {
      data:[],
    }
  },
  mounted() {
    this.$event.on('langChange',(arg)=>{
      this.init();
    })
    this.init();
  },
  methods:{
    init(){
      getNews().then(res => {
        if(res.data.code !== 500 ) {
          this.data = res.data.data
        }
      })
    },
  }
}
</script>

<style scoped >
.News {

}
.News > .content {
  padding: 0 20px;
}

.list {
  height: calc(100vh - 100px);
  overflow-y: scroll;
}

</style>
