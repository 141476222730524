// 创建文件en.js
const en = {
    language: {
        zh: 'Chinese',
        en: 'English',
    },
    project:'Project',
    news:'News',
    event:'Event',
    award:'Award',
    company:'Company',
    careers:'Careers',
    contact:'Contact',
    architecture:'Architecture',
    indoor:'Indoor',
    landscape:'Landscape',
    planning:'Planning',
    future:'Future',
    business:'Business',
    hotel:'Hotel',
    educate:'Educate',
    culture:'Culture',
    office:'Office',
    house:'House',
    art:'Art',
    client:'Client',
    status:'Status',
    area:'Area',
    search:'Search',
    address:'Address',
    telephone:'Telephone',
    mailbox:'Mailbox',
    socialize:'Socialize',
    intro:"Intro",
    team:"Team"
}
export default en;
