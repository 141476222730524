<template>
  <div class='Jobs'>
    <mobile-header>
      <h1 class="mobile_header">CAREERS</h1>

    </mobile-header>
  </div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader";
export default {
  name: "Jobs",
  components: {MobileHeader}
}
</script>

<style scoped >
.Jobs {

}
</style>
