<template>
  <el-dialog
      :title="data.id ? '编辑' : '新增' "
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="50%"
  >
    <el-form ref="proForm"  :mode="data" >
      <el-form-item label="岗位">
        <el-input v-model="data.job"></el-input>
      </el-form-item>
      <el-form-item label="地区">
        <el-input v-model="data.lcoation"></el-input>
      </el-form-item>
      <el-form-item label="截止日期">
        <el-input v-model="data.deadline"></el-input>
      </el-form-item>
      <el-form-item label="职位详情">
        <simditor  v-model="data.info" id="jobInfo"></simditor>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button @click="cancel">取 消</el-button>
    <el-button type="primary" @click="save">确 定</el-button>
  </span>
  </el-dialog>
</template>

<script>
import {getImgUrl} from "@/api/api";
import {guid} from "@/utils/common";
import Simditor from "@/components/Simditor";

export default {
  name: "JobForm",
  components: {Simditor},
  data(){
    return {
      data:{},
      dialogVisible:false,
    }
  },
  methods:{
    open(data){
      this.dialogVisible = true;
      this.data = data;
    },
    save(){
      this.$emit('save',this.data)
      this.dialogVisible = false;
    },
    cancel(){
      this.dialogVisible = false;
    },
    logoUpload(res, file){
      this.data.cover = getImgUrl(file)
    }
  }
}
</script>

<style scoped>

</style>
