<template>
  <div class='Company'>
    <mobile-header>
      <h1 class="mobile_header">COMPANY</h1>
      <img  :src="allData.bigImg">
      <div style="" v-html="allData.companyInfo"></div>
      <img :src="allData.infoImg">
      <h2 class="mobile_header">PEOPLE</h2>
      <div  v-for="(partner) in partners" :key="partner.id" style="display: flex;margin-bottom: 50px;padding-left: 10px;">
        <img :src="partner.headImg" width="100px" height="100px" style="margin-right: 10px;margin-top: 25px">
        <div v-html="partner.content">
        </div>
      </div>
    </mobile-header>

  </div>
</template>

<script>
import MobileHeader from "@/components/MobileHeader";
import {getCommon, getNews, getPartner} from "@/api/api";
export default {
  name: "Company",
  components: {MobileHeader},
  data(){
    return{
      allData:{},
      partners:[],
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init() {
      getCommon().then(res => {
        if (res.data.code !== 500) {
          this.allData = res.data.data[0]
        }
      })
      getPartner().then(res => {
        if (res.data.code !== 500) {
          this.partners = res.data.data
        }
      })
      // getNews().then(res => {
      //   if(res.data.code !== 500 ) {
      //     this.jxData = res.data.data.filter(item => item.type.includes('award'))
      //   }
      // })
    },
  }
}
</script>

<style scoped >
.Company {

}
</style>
