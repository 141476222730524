<template>
      <div  class="sub_menus">
      <div
          class="type_item"
          :class="activeMenu.label === item.label ? 'type_item_choose' : '' "
          v-for="item in menuList"
          :key="item.label"
          @click="menuClick(item)"
      >
        <div class="active_panle">
          <transition
              name="animate__animated animate__zoom"
              leave-active-class="animate__zoomOut"
              enter-active-class="animate__zoomIn"
          >
              <span
                  class="item_active"
                  v-show="activeMenu.label === item.label "
              ></span>
          </transition>
        </div>
        <div class="item_label">{{ $t(item.label) }}</div>
      </div>
    </div>
</template>

<script>
export default {
    name:'SubMenus',
    props:{
        menuList:{
            default:[]
        },
        activeMenu:{
            default:{}
        }
    },
    data(){
        return {
        }
    },
    methods:{
        menuClick(val){
            this.$emit('change',val)
        }
    }
}
</script>
<style>
.sub_menus {
  position: fixed;
  left: 7px;
  top: 300px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color: black;
  display: flex;
  align-items: center;
}
.sub_menu {
  opacity: 0.5;
  cursor: pointer;
}
.sub_menu:hover {
  opacity: 1;
}
</style>
