<template>
  <div class='MobileHeader'>
    <div class="header">
      <div @click="menuDrawerVisible=!menuDrawerVisible">
        <logo :menu-show="menuDrawerVisible" is-mobile="true"></logo>
      </div>
      <div @click="typeDrawerVisible=!typeDrawerVisible" v-if="isProject" style="font-size: 12px">筛选</div>
    </div>
    <div class="list">
      <slot></slot>
    </div>
    <!--  组件  -->
    <el-drawer
        :modal="false"
        size="30%"
        :visible.sync="menuDrawerVisible"
        direction="ltr"
        :withHeader="false"
        custom-class="menuDrawer"
    >
      <div>
        <left-menu :is-mobile="true" @projectClick="projectClick"></left-menu>
      </div>
    </el-drawer>
    <el-drawer
        :modal="false"
        size="30%"
        :visible.sync="typeDrawerVisible"
        direction="rtl"
        :withHeader="false"
        custom-class="typeDrawer"
    >
      <div>
        抽屉
      </div>
    </el-drawer>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu";
import Logo from "@/components/Logo";

export default {
  name: "MobileHeader",
  components: {Logo, LeftMenu},
  data(){
    return {
      menuDrawerVisible:false,
      typeDrawerVisible:false,
      isProject:true,
    }
  },
  methods:{
    projectClick(val) {
      this.isProject = val;
    }
  }
}
</script>

<style scoped >
.MobileHeader {


}
.header {
  padding: 0 20px;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list {
  height: calc(100vh - 40px);
  padding: 0 20px;
  overflow-y: scroll;
}
</style>
