<template>
  <el-dialog
      :title="data.id ? '编辑' : '新增' "
      :visible.sync="dialogVisible"
      :modal-append-to-body="false"
      width="30%"
  >
    <el-form ref="proForm" :mode="data" >
      <el-form-item label="项目名称">
        <el-input v-model="data.projectName"></el-input>
      </el-form-item>
      <el-form-item label="位置">
        <el-input v-model="data.location"></el-input>
      </el-form-item>
      <el-form-item label="开始年份">
        <el-input v-model="data.beginDate"></el-input>
      </el-form-item>
      <el-form-item label="客户">
        <el-input v-model="data.client"></el-input>
      </el-form-item>
      <el-form-item label="面积">
        <el-input v-model="data.size"> </el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-input v-model="data.status"></el-input>
      </el-form-item>
      <el-form-item label="分类">
        <el-cascader v-model="dataType" :options="typeOptions" @change="typeChange" :props="{ expandTrigger: 'hover' }"></el-cascader>
      </el-form-item>
      <el-form-item label="图标">

        <!-- <el-upload class="upload-demo" action="https://www.bv-design.cn/api/upload" list-type="picture" -->
                   <!-- :on-success="logoUpload" :file-list="logoList"> -->
          <el-button size="small" type="primary" @click="$img.open()">点击上传</el-button>
        <!-- </el-upload> -->
      </el-form-item>
      <el-form-item label="效果图">
        <el-upload class="upload-demo" action="#" list-type="picture" :on-success="coverUpload" :multiple="true" :file-list="coverList"
                   :http-request="fileChange" ref="batchUpload">
          <el-button size="small" type="primary">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="公司-介绍">
        <simditor id="companyInfo"  v-model="data.content"></simditor>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="save">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {getImgUrl, upload} from "@/api/api";
import {guid} from "@/utils/common";
import Simditor from "../../Simditor";

export default {
  name: "ProjectForm",
  components: {Simditor},
  data(){
    return {
      data:{},
      dialogVisible:false,
      typeOptions: [
        {
          label: "建筑",
          value: "architecture",
          children: [
            {
              label: "商业",
              value: "business"
            },
            {
              label: "酒店",
              value: "hotel"
            },
            {
              label: "教育",
              value: "educate"
            },
            {
              label: "文化",
              value: "culture"
            },
            {
              label: "办公",
              value: "office"
            }, {
              label: "自宅",
              value: "house"
            }, {
              label: "艺术",
              value: "art"
            },{
              label: "公共",
              value: "public"
            },
          ],
        },
        {
          label: "室内",
          value: "indoor",
          children: [
            {
              label: "商业",
              value: "business"
            },
            {
              label: "酒店",
              value: "hotel"
            },
            {
              label: "教育",
              value: "educate"
            },
            {
              label: "文化",
              value: "culture"
            },
            {
              label: "办公",
              value: "office"
            }, {
              label: "自宅",
              value: "house"
            }, {
              label: "艺术",
              value: "art"
            },
          ],
        },
        {
          label: "景观",
          value: "landscape",
        },
        {
          label: "规划",
          value: "planning",
        },
        {
          label: "未来",
          value: "future",
        },
      ],
      contentEditor:null,
      logoList:[],
      coverList:[],
      dataType:null
    }
  },
  mounted() {
    this.initEditor();
  },
  methods:{
    typeChange(val){
      this.data.type = val.join(',')
    },
    initEditor(){

    },
    open(data){
      this.logoList = []
      this.coverList = []
      this.dialogVisible = true;
      this.data = data;
      const logos = [this.data.projectLogo]
      const covers = [this.data.cover]
      if(this.data.opts) {
        covers.push(...this.data.opts.split(','))
      }
      this.logoList = logos.map(item => {
        return {
          uid:guid(),
          url:item
        }
      })
      this.coverList = covers.map(item => {
        return {
          uid:guid(),
          url:item
        }
      })
      if(data.type){
        this.dataType = data.type.split(',')
        console.log(this.dataType)
      }
    },
    save(){
      this.$emit('save',this.data)
      this.dialogVisible = false;
    },
    cancel(){
      this.dialogVisible = false;
    },
    logoUpload(res, file){
      this.data.projectLogo = getImgUrl(file)
      this.logoList.push({
        uid:guid(),
        url:this.data.projectLogo
      })
    },
    fileChange(req) {
      upload(req.file).then((res) => {
        if ((!this.data.opts || this.data.opts.length === 0) && !this.data.cover) {
          this.data.cover = getImgUrl(req.file);
          this.coverList.push({
            uid:guid(),
            url:this.data.cover
          })
        } else {
          if(!this.data.opts) {
            this.data.opts = []
          }
          this.data.opts.push(getImgUrl(req.file));
          this.coverList.push(...this.data.opts.map(item => {return {uid:guid(),url:item.image}}))
        }
      });
    },
    coverUpload(res, file) {
      this.data.cover = getImgUrl(file);
    },
  }
}
</script>

<style scoped>

</style>
