export function guid() {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}


/**
 * 判断是否为手机访问
 * @return {RegExpMatchArray}
 */
export const isMobile = function () {
    // if(navigator.userAgent.match(/(Pad|iPad)/))return false;
    return navigator.userAgent.match(/(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
}

export function debounce(fn, wait = 50) {
    // 通过闭包缓存一个定时器 id
    let timer = null
    // 将 debounce 处理结果当作函数返回
    // 触发事件回调时执行这个返回函数
    return function(...args) {
        // this保存给context
        const context = this
        // 如果已经设定过定时器就清空上一次的定时器
        if (timer) clearTimeout(timer)

        // 开始设定一个新的定时器，定时器结束后执行传入的函数 fn
        timer = setTimeout(() => {
            fn.apply(context, args)
        }, wait)
    }
}


export function recursiveToTree(data) {
    function loop(key) {
        const arr = [];
        data.forEach((item) => {
            if (item.parentId === key) {
                item.children = loop(item.id);
                arr.push(item);
            }
        });
        return arr;
    }
    return loop(null);
}
