<template>
  <div class="admin_content">
    <div style="padding:20px;">
      <span style="margin-right:10px">语音选择</span>
      <el-radio-group v-model="lang" @input="langChange">
        <el-radio-button label="zh">中文</el-radio-button>
        <el-radio-button label="en">英文</el-radio-button>
      </el-radio-group>
   </div>
    <el-tabs tab-position="left" style="height: 90vh; margin-top: 30px; overflow-y: scroll">
      <el-tab-pane label="项目管理">
        <project-table :lang="lang"></project-table>
      </el-tab-pane>
      <el-tab-pane label="新闻管理">
        <news-table :lang="lang"></news-table>
      </el-tab-pane>
      <el-tab-pane label="就业管理">
        <job-table :lang="lang"></job-table>
      </el-tab-pane>
      <el-tab-pane label="团队管理">
<!--        <h2>合伙人</h2>-->
        <partner-table :lang="lang"></partner-table>
<!--        <h2>设计团队</h2>-->
<!--        <team-table :lang="lang"></team-table>-->
      </el-tab-pane>
      <el-tab-pane label="其他信息">
        <common-form :lang="lang"></common-form>
      </el-tab-pane>

    </el-tabs>
  </div>
</template>

<script>
import {getImgUrl, download, upload} from "../api/api";
import NewsTable from "@/components/admin/news/NewsTable";
import JobTable from "../components/admin/job/JobTable.vue";
import CommonForm from "@/components/admin/CommonForm";
import PartnerTable from "@/components/admin/company/partner/PartnerTable";
import ProjectTable from "@/components/admin/project/ProjectTable";
export default {
  components: {ProjectTable, PartnerTable, CommonForm, NewsTable, JobTable },
  data() {
    return {
      lang:'zh',
    };
  },
  computed:{

  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
.admin_content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
  background-color: white;
}

.upload-demo {
  display: inline-block;
  margin-right: 20px;
}

.action_group {
  position: fixed;
  right: 0;
  top: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 200px;
}

.action_group button {
  margin-right: 20px;
  margin-left: 0px !important;
}
</style>
