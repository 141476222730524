// 创建文件zh.js
const zh = {
    language: {
        zh: '中文',
        en: '英文',
    },
    project:'项目',
    news:'新闻',
    event:'事件',
    team:'团队',
    intro:'简介',
    award:'奖项',
    company:'公司',
    careers:'就业',
    contact:'联系',
    architecture:'建筑',
    public:"公共",
    indoor:'室内',
    landscape:'景观',
    planning:'规划',
    future:'未来',
    business:'商业',
    hotel:'酒店',
    educate:'教育',
    culture:'文化',
    office:'办公',
    house:'自宅',
    art:'艺术',
    client:'客户',
    status:'状态',
    area:'面积',
    search:'搜索',
    address:'地址',
    telephone:'电话',
    mailbox:'邮箱',
    socialize:'社交'
}
export default zh;
