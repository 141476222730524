<template>
  <div class="main_content">
    <div class="menu_name">{{ $t('contact') }}</div>
    <div>
      <h2>
            <p class="title_block">
              <span class="job_title">{{ $t('address') }}</span>
            </p>
          </h2>
          <p class="desc_block">
            <span class="job_desc">
              中国(四川)成都高新区吉庆四路与吉瑞四路交叉口环球时代中心C座1118号
            </span>
            <br />
          </p>
          <h2>
            <p class="title_block">
              <span class="job_title">{{ $t('telephone') }}</span>
            </p>
          </h2>
          <p class="desc_block">
            <span class="job_desc">
              17380418804
            </span>
            <br />
          </p>
          <h2>
            <p class="title_block">
              <span class="job_title">{{ $t('mailbox') }}</span>
            </p>
          </h2>
          <p class="desc_block">
            <span class="job_desc">
              bvdesign@foxmail.com
            </span>
            <br />
          </p>
          <h2>
            <p class="title_block">
              <span class="job_title">{{ $t('socialize') }}</span>
            </p>
          </h2>
          <p class="desc_block">
            <span class="job_desc">
              wx:17380418804
            </span>
            <br />
          </p>
    </div>
  </div>
</template>

<script>
export default {
  name:'Contact'
}
</script>

<style>

</style>
