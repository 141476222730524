<template>
  <div class="swiper" :id="swiperId" style="height: 30%;" :style="{width:minWidth + 'px'}">
    <div class="swiper-wrapper">
      <div class="swiper-slide">
        <project-info  :id="'info_' + swiperId" :show-more="isMore" :data="project"></project-info>
      </div>
      <div
        :id="'temp-img-' + swiperId"
        class="swiper-slide temp-img"
        @click="tempClick"
      >
        <img :src="project.cover" />
      </div>
      <transition
        name="animate__animated animate__fade"
        leave-active-class="animate__fadeOutRight"
        enter-active-class="animate__fadeInRight"
        v-for="(item,idx) in project.opts.split(',')"
        :key="'pro-opt-' + idx"
      >
        <div v-if="isMore" class="swiper-slide opt-img" @click="tempClick">
          <img :src="item">
<!--          <img v-if="item.image" :src="item.image" />-->
<!--          <div v-else class="opt-text">{{ item.content }}</div>-->
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Swiper from "./swiper-bundle.min.js";
import anime from "animejs";
import ProjectInfo from "../projectInfo/ProjectInfo.vue";
import {getScrollTopByCenter, scrollIntoViewCenter} from "@/utils/elementUtil";
import { scrollTop } from "../../utils/elementUtil";

export default {
  name: "ImageSwiper",
  components: { ProjectInfo },
  props: ["project","swiperId"],
  data() {
    return {
      isMore: false,
      swiper: null,
      maxWidth:document.getElementById('pro_block').clientWidth,
      minWidth:0,
      maxInfoWidth:0,
    };
  },
  mounted() {
    this.init();
    this.initListener()
  },
  beforeDestroy() {
    this.removeListener()
  },
  methods: {
    initListener(){
      window.addEventListener('resize',this.windowResize)
    },
    removeListener(){
      window.removeEventListener('resize',this.windowResize)
    },
    windowResize(){
      this.maxWidth = document.getElementById('pro_block').clientWidth;
      this.minWidth = this.maxWidth * 0.40;
      this.maxInfoWidth = this.maxWidth * 0.25
      console.log(this.minWidth,this.maxInfoWidth)
    },
    init() {
      this.swiper = new Swiper("#" + this.swiperId, {
        freeMode: true,
        slidesPerView: "auto",
        // loop:true,
        // loopedSlides:1,
        grabCursor: true,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        // slideToClickedSlide: true,
      });
      this.windowResize();
    },
    scroll(){
      scrollTop(this.$el,document.getElementById('pro_block'))
    },
    tempClick() {
      if (this.isMore) {
        this.closeMore();
        return;
      }
      this.scroll();
      this.$event.emit('projectClick')
      let ani = anime.timeline({
        targets: ["#" + this.swiperId],
        height: "70%",
        width:this.maxWidth,
        easing: "cubicBezier(0.545, 0.115, 0.485, 0.945)",

      }).add({
        targets:['#' + 'info_' + this.swiperId],
        width:this.maxInfoWidth,
        easing: "cubicBezier(0.545, 0.115, 0.485, 0.945)",
      });

      this.isMore = true;
      ani.finished.then(() => {
        this.$nextTick(()=>{
          // this.swiper.slideNext(1000)
        })
      });
    },
    closeMore() {
      this.isMore = false;
      // this.swiper.slideTo(0,1000)
      anime.timeline({
        targets: ["#" + this.swiperId],
        height: "30%",
        width: this.minWidth,
        easing: "cubicBezier(0.545, 0.115, 0.485, 0.945)",
      }).add({
        targets:['#' + 'info_' + this.swiperId],
        width:'150px',
        easing: "cubicBezier(0.545, 0.115, 0.485, 0.945)",
      });
    },
  },
};
</script>


<style scoped>
@import "./swiper-bundle.min.css";

.swiper {

  height: 30%;
  transform-origin: 50% 50%;


}
.swiper-wrapper {
  user-select: none;
}
.swiper-slide {
  text-align: center;
  font-size: 14px;
  background: #fff;
  width: auto;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
}
.opt-text {
  width: 300px;
  white-space: pre-wrap;
  text-align: justify;
  padding: 20px;
  height: calc(100% - 40px);
  line-height: 1.5em;
}
.temp-img {
  height: 100%;
  cursor: pointer;
}
.swiper-slide img {
  height: 100%;
  width: 100%;
}
.opt-img {
  height: 100%;
}
</style>
