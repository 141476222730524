<template>
  <div  class="projects">
      <image-swiper v-for="(item,i) in data" :swiper-id="('pro1' + i)" :key="i"  :project="item" class="block" ></image-swiper>
    <concat-panel></concat-panel>
  </div>
</template>

<script>
import { scrollIntoViewCenter } from "../utils/elementUtil";
import ImageSwiper from "./imgSwiper/ImageSwiper.vue";
import ConcatPanel from "@/components/ConcatPanel";

export default {
  components: { ImageSwiper ,ConcatPanel},
  props:['data'],
  data() {
    return {
      lastAni: "",
    };
  },
  methods: {
    backToTop(){
      document.getElementById('pro_block').scrollTo({top:0,behavior:'smooth'})
    }
  },
};
</script>

<style scoped>
.projects {
  height: 100%;


}
.block {
  margin-bottom: 40px;
}
.back {
  height: 140px;
    line-height: 20px;
    width: 100%;
    text-align: center;
    cursor: pointer;
}
</style>
