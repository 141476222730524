<template>
  <div>
    <div v-for="(item,idx) in list" :key="idx"  class="people_panel">
      <div class="left_panel">
        <div class="name" @click="openPanel(idx)">
          <icon-plus :rote="(activeIdx === idx)"></icon-plus><span style="display:inline-block">{{item.name}}</span>
        </div>
        <div :id="('peoplePanel' + idx)"  class="msg">{{item.msg}}</div>
      </div>
      <div class="right_panel">
        <img v-if="activeIdx === idx && item.img" :src="item.img" />
      </div>
    </div>
  </div>
</template>

<script>
import anime from 'animejs';
import iconPlus from './icon-plus.vue';

export default {
  components: { iconPlus },
  data(){
    return {
      list:[{
        name:'Leo',
        msg:'张胜超，创始人，建筑师和室内设计师，曾任职中建西南设计院，曾 参于重庆江北T3机场、青岛胶东机场、中法蒙彼利埃小学、 玉林中学等公建建筑设计。',
        img:require("@/assets/img/leo.png")
      },{
        name:'KONA',
        msg:'孔令娜，联合创始人，毕业于英国利兹大学经济硕士，兼修意大利马 兰欧尼设计学院（伦敦校区）Fashion Image Consultant。 曾任职风险投资公司，对项目管理有丰富经验。'
      },{
        name:'Carr',
        msg:'刘强，联合创始人，建筑师和规划师，毕业于熊本大学建筑学硕士及 环境共生工学博士。\n期刊发表：\nTransportation Research Part A\nInternational Review for Spatial Planning and Sustainable Development  American Journal of Civil Engineering and Architecture'
      }],
      activeIdx:-1
    }
  },
  methods:{
    openPanel(i){
      if(this.activeIdx === i ) {
        this.closePanel(i);
        this.activeIdx = -1;
        return;
      } else {
        this.closePanel(this.activeIdx);
      }

      this.activeIdx = i;
      const p = document.getElementById("peoplePanel" + i);
      p.style.height='auto'
      const autoHeight= p.clientHeight + 20;
      p.style.height='0px'
      anime({
        targets: "#peoplePanel" + i,
        height: autoHeight + "px",
        easing:'linear',
        duration:500
      })
     
    },
    closePanel(i){
      if(i === -1) {
        return;
      }
      anime({
        targets: "#peoplePanel" + i,
        height: "0px",
        // easing: "spring(1, 80, 5, 0)",
        easing:'linear',
        duration:100
        
      })
    }
    
  }
}
</script>

<style scope>
.people_panel {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.left_panel {
  width: 60%;
}
.right_panel {
  width: 30%;
}
.name {
  user-select: none;
  cursor: pointer;
  display: flex;
    align-items: center;
    margin-bottom: 10px;
}
/* .name:hover{
  font-size: 24px;
} */
.msg {
  white-space: pre-wrap;
  overflow: hidden;
    height: 0px;
}
</style>