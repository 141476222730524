<template>
  <div class='CheckBoxGroup'>
    <el-checkbox-group v-model="checkList">
      <el-checkbox v-for="(item,index) in data" :key="index" :label="item.url">
        <slot :item="item"></slot>
      </el-checkbox>
    </el-checkbox-group>
  </div>
</template>

<script>
export default {
  name: "CheckBoxGroup",
  props:['data'],
  data(){
    return {
      checkList:[]
    }
  }
}
</script>

<style scoped >
.CheckBoxGroup {

}
</style>
<style>
.el-checkbox-group {
  display: grid;
  grid-template-columns:repeat(auto-fill,minmax(200px,1fr));
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}
.el-checkbox {
  display: flex;
  margin: 0;
  justify-content: center;
}
</style>

