<template>
    <div class="add"  >
      <svg t="1673869575600" class="icon" :class="rote ? 'add__rote' : '' " viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2686" width="14" height="14"><path d="M851.968 503.808 532.48 503.808 532.48 188.416c0-12.288-8.192-20.48-20.48-20.48S491.52 176.128 491.52 188.416l0 319.488L172.032 507.904c-12.288 0-20.48 8.192-20.48 20.48s8.192 20.48 20.48 20.48L491.52 548.864l0 319.488c0 12.288 8.192 20.48 20.48 20.48s20.48-8.192 20.48-20.48l0-319.488 319.488 0c12.288 0 20.48-8.192 20.48-20.48S864.256 503.808 851.968 503.808z" p-id="2687" fill="#000000"></path></svg>
    </div>
</template>

<script>
export default {
  name:'iconPlus',
  props:['rote']
}
</script>

<style scoped>
.add {
  display: inline-block;
  height: 16px;
}
.add__rote {
  transform: rotate(45deg);
  transform-origin: center;
}
</style>