<template>
  <div class="tracking-in-expand">
    <div class="logo_main" :class="isMobile ? 'mobile_logo_main':''">
      <div class="logo_text" v-if="!isMobile">BV</div>
      <div class="logo_icon">
        <transition
            name="animate__animated animate__zoom"
            leave-active-class="animate__zoomOut"
            enter-active-class="animate__zoomIn"
        >
          <div class="line" v-show="!menuShow"></div>
        </transition>
        <div class="line"></div>
        <transition
            name="animate__animated animate__zoom"
            leave-active-class="animate__zoomOut"
            enter-active-class="animate__zoomIn"
        >
          <div class="line" v-show="!menuShow"></div>

        </transition>
      </div>
    </div>
    <!-- <div class="logo_img">
        <img width="25px" src="@/assets/img/logo_black.jpg" />
    </div> -->

  </div>
</template>
<script>
export default {
  props: ['menuShow', 'isMobile']
}
</script>
<style scoped>
.tracking-in-expand {
  z-index: 10;
  color: black;
  font-weight: bold;
  width: 30%;
  font-size: 30px;
  cursor: pointer;

}

.animate__animated.animate__bounceInDownv {
  --animate-duration: 1s;
}

.logo_main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
  width: 30px;
}

.logo_icon {
  height: 28px;
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mobile_logo_main {
  margin: 0;
}
.mobile_logo_main > .logo_icon {
  transform: scale(0.5);
}

.line {
  height: 4px;
  width: 42px;
  background-color: black;
  margin-bottom: 5px;
}
</style>
