<template>
  <div class="mobileHome">
    <mobile-header>
      <mobile-image  v-for="(item,i) in proList" :swiper-id="('pro1' + i)" :key="i"  :project="item" style="margin-bottom: 20px"></mobile-image>
    </mobile-header>
  </div>
</template>

<script>
import {download, getProjects} from "@/api/api";
import MobileImage from "@/components/imgSwiper/MobileImage";
import LeftMenu from "@/components/LeftMenu";
import MobileHeader from "@/components/MobileHeader";

export default {
  name: "Home",
  components: {MobileHeader, MobileImage},
  data(){
    return {
      proList:[],
      allList:[]
    }
  },
  mounted() {
    this.init();
  },
  methods:{
    init(){
      getProjects().then(res => {
        this.allList = res.data.data.sort((a,b)=>{
          return a.beginDate > b.beginDate ? -1 : 1
        })
        this.proList = this.allList
      })
    },
    projectChange(type,subType){
      if(type) {
        this.proList = this.allList.filter(item => {
          return item.type[0] === type.label
        })
      }

      if(subType) {
        this.proList = this.proList.filter(item => {
          return item.type[1] === subType.label
        })
      }
    },
    searchChange(data) {
      if(!data) {
        this.proList = this.allList
        return
      }
      this.proList =  this.allList.filter(item => {
        return item.projectName.includes(data)
      })
    },

  }
}
</script>

<style scoped>
.mobileHome {
  height: 100vh;
  width: 100vw;
}

.lists {
  width: calc(100% - 30%);
  padding: 0 15%;
  height: calc(100vh - 40px);
  overflow-y: scroll;

}

</style>
<style>
.menuDrawer , .typeDrawer{
  box-shadow:none !important;
  top: 40px !important;
  height: calc(100% - 40px);
}
</style>
