<template>
  <div class="left_menu" :class="isMobile ? 'left_menu_pos_mobile' : 'left_menu_pos'">
    <div class="item" v-for="item in menus" :key="item" @click="menuClick(item)" :class="activeMenu === item ? 'type_item_choose' : ''">{{ $t(item) }}</div>
    <div class="item"  @click="changeLang($i18n.locale === 'zh' ? 'en' : 'zh' )" >{{ $i18n.locale === 'zh' ? $t('language.en') : $t('language.zh')  }}</div>
  </div>
</template>

<script>
export default {
  name: "LeftMenu",
  props:['isMobile'],
  data() {
    return {
      menus: ["project", "news", "company", "careers", "contact"],
      activeMenu:'项目'
    };
  },
  methods:{
    menuClick(val){
      if(val === 'contact') {
          document.getElementsByClassName('main_content')[0].scrollTop = 999999
        return;
      }
      this.activeMenu = val;
      this.$router.push({name:val})
      this.$emit('projectClick',val === 'project')
    },
    changeLang(type) {
      console.log('语言切换',type)
      this.$i18n.locale = type;
      this.$event.emit('langChange',type)
    },
  }
};
</script>

<style scoped>
.left_menu_pos_mobile {
  left: 20px;
  position: absolute;
}
.left_menu_pos {
  position: fixed;
  left: 35px;
  top: 75px;
}
.left_menu {
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  color:black;
}
.item {
  width: 100%;
  opacity: 0.5;
  cursor: pointer;
}
.item:hover{
  opacity: 1;
}
</style>
