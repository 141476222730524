<template>
  <div class="main_content">
    <div class="menu_name">News</div>
    <div class="news_list">
      <div class="news_item" v-for="(item) in data" :key="item.id">
        <div class="timeline">{{item.date}}</div>
        <img width="400px"
          style="margin-right: 10px; margin-left: 10px"
          :src="item.cover"/>
        <div class="desc">
          <div class="title">{{item.title}}</div>
          <div class="text" v-html="item.content">
          </div>
        </div>
      </div>
    </div>
    <concat-panel></concat-panel>

  </div>
</template>

<script>
import ConcatPanel from '../components/ConcatPanel.vue';
import {download, getNews} from "@/api/api";
export default {
  components: { ConcatPanel },
  name: "News",
  data(){
    return {
      data:[],
      allData:[]
    }
  },
  mounted() {
    this.$event.on('langChange',(arg)=>{
      this.init();
    })
    this.init();
  },
  methods:{
    init(){
      getNews().then(res => {
        if(res.data.code !== 500 ) {
          this.data = res.data.data.filter(item => item.type.includes('news'))
        }
      })
    },
  }
};
</script>

<style>
.menu_name {
  font-size: 100px;
  font-weight: bold;
  line-height: 140px;
}

.news_item {
  display: flex;
  margin-bottom: 50px;
}
.desc {
  text-align: justify;
  line-height: 1.8em;
}
.title {
  font-weight: bold;
  font-size: 20px;
  line-height: 40px;
}
</style>
<style scoped>
.news_list {
  padding-left: 140px;
}

</style>
