<template>
  <div class="menu">
    <div class="menus_container">
      <logo @click.native="logoClick" :menu-show="leftMenuShow"></logo>
      <div class="m_types" v-show="projectShow" >
        <div
          class="type_item"
          v-for="item in menuList"
          :key="item.label"
          :class="activeMenu.label === item.label ? 'type_item_choose' : '' "
          @click="menuClick(item)"
        >
          <div class="active_panle" >
            <transition
              name="animate__animated animate__zoom"
              leave-active-class="animate__zoomOut"
              enter-active-class="animate__zoomIn"
            >
              <span
                class="item_active"
                v-show="activeMenu.label === item.label && !subActiveMenu.label"
              ></span>
            </transition>
          </div>
          <div class="item_label">{{ $t(item.label) }}</div>
        </div>
      </div>
      <div class="search" v-show="projectShow">
        <span style="font-size: 20px;font-weight: bold;color: black;margin-right: 20px;width: 80px">山间建筑</span>
        <div style="position: relative;display: flex;align-items: center;">
          <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style="touch-action: none"
          >
            <circle
                cx="7.5"
                cy="5.5"
                r="4.5"
                stroke="black"
                style="touch-action: none"
            ></circle>
            <path d="M4 9L1 12" stroke="black" style="touch-action: none"></path>
          </svg>
          <input slot="reference" class="search_input" id="search" :placeholder="$t('search')" @input="searchChange" @focus="visible=true" @blur="visible=false" />
          <div v-show="visible" class="inputPlaceholder">
            <ul>
              <li @mousedown.capture="setSearchVal('酒店')">酒店</li>
              <li @mousedown.capture="setSearchVal('商业')">商业</li>
              <li @mousedown.capture="setSearchVal('图书馆')">图书馆</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div v-show="projectShow && subMenuShow &&  activeMenu.subMenu" class="sub_types">
      <div
        class="type_item"
        v-for="item in activeMenu.subMenu"
        :key="item.label"
        :class="subActiveMenu.label === item.label ? 'type_item_choose' : '' "
        @click="subMenuClick(item)"
      >
        <div class="active_panle">
          <transition
            name="animate__animated animate__zoom"
            leave-active-class="animate__zoomOut"
            enter-active-class="animate__zoomIn"
          >
            <span
              class="item_active"
              v-show="subActiveMenu.label === item.label"
            ></span>
          </transition>
        </div>
        <div class="item_label">{{ $t(item.label) }}</div>
      </div>
    </div>
    <transition
    name="animate__animated animate__fade"
              leave-active-class="animate__fadeOutLeft"
              enter-active-class="animate__fadeInLeft">

              <left-menu v-show="leftMenuShow" @projectClick="projectClick"></left-menu>
    </transition>

  </div>
</template>

<script>
import LeftMenu from "./LeftMenu.vue";
import Logo from "./Logo.vue";
import {debounce} from "@/utils/common";
export default {
  components: { Logo, LeftMenu },
  name: "menus",
  data() {
    return {
      visible:false,
      projectShow:this.$router.currentRoute.name === 'project' || this.$router.currentRoute.name === 'company',
      menuList: [
        {
          label: 'architecture',
          subMenu: [
            {
              label: "business",
            },
            {
              label: "hotel",
            },
            {
              label: "educate",
            },
            {
              label: "culture",
            },
            {
              label: "office",
            },{
              label: "house",
            },{
              label: "art",
            },{
              label: "public",
            },
          ],
        },
        {
          label: "indoor",
          subMenu: [
          {
              label: "business",
            },
            {
              label: "hotel",
            },
            {
              label: "educate",
            },
            {
              label: "culture",
            },
            {
              label: "office",
            },{
              label: "house",
            },{
              label: "art",
            },
          ],
        },
        {
          label: "landscape",
          subMenu: [],
        },
        {
          label: "planning",
          subMenu: [],
        },
        {
          label: "future",
          subMenu: [],
        },
      ],
      activeMenu: {},
      subActiveMenu: {},
      subMenuShow:false,
      leftMenuShow: false,
      searchDebounce:debounce((data) => {this.$event.emit('searchChange',data)},500)
    };
  },
  mounted(){
    this.$event.on('projectClick',()=>{
      this.subMenuShow = false;
    })
  },
  methods: {
    menuClick(item) {
      this.subActiveMenu = {};
      this.activeMenu = item;
      this.subMenuShow = true
      this.$event.emit('menuClick',this.activeMenu,null)
    },
    subMenuClick(item) {
      this.subActiveMenu = item;
      this.$event.emit('menuClick',this.activeMenu,this.subActiveMenu)
    },
    logoClick() {
      this.leftMenuShow = !this.leftMenuShow;
    },
    projectClick(val){
      this.projectShow = val;
      this.logoClick();
    },
    searchChange(e){
      if(e){
        this.searchDebounce(e.data)
      }
    },
    setSearchVal(val){
      const s = document.getElementById('search');
      s.value = val;
      this.searchChange({data:val})
    }
  },
};
</script>

<style>
.menu {
  position: sticky;
  background-color: white;
  z-index: 2000;
  padding: 0px 15px 0px 15px;
  top: 0;
  /* width: calc(100% - 40px); */
}
.menus_container {
  width: 100%;
  /* min-height: 40px; */
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.m_types {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.sub_types {
  /* width: 40%;
  padding-left: 300px; */
  font-size: 10px;
  text-align: center;
  display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -10px;
}
.type_item {
  /* display: inline-block; */
  height: 40px;
  /* width: 60px; */
  display: inline-flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  opacity: 0.6;
}
.type_item_choose {
  opacity: 1 !important;
}
.search {
  width: 30%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.item_label {
  height: 26px;
}
.active_panle {
  width: 20px;
  height: 26px;
}
.item_active {
  width: 5px;
  height: 5px;
  background-color: black;
  display: inline-block;
}

.search_input {
  border: none;
  outline: none;
  height: 13px;
  margin-left: 5px;
  width: 150px;
}

.el-popover{
  padding: 0;
}
ul {
  padding: 0;
  list-style-type: none;
  margin-block: 0;

  li {
    padding: 5px;
    cursor: pointer;
  }
}

.inputPlaceholder {
  position: absolute;
  padding: 10px 0;
  background-color: #fff;
  width: 100%;
  font-size: 14px;
  color: #8f8f8f;
  top: 15px;
  left: 20px;
}
</style>
